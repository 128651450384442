.am-question {
    margin-left: 10px;
}
.am-questions {
    margin: 10px 0px 0px 5px;
}
.am-options {
    margin: 10px;
}
.btn-extract {
    background: #0063C3;
    color: #fff;
    border: 1px solid #0063C3;
    padding: 4px 14px 9px 14px;
    border-radius: 5px;
    vertical-align: bottom;
    margin: 10px;
}

.add-btn-icon {
    /* width: 25px;
    height: 25px; */
    margin-top: 5%;
}
.noColor {
    background-color: white !important;
    color: black !important;
}
.card .noColor {
    padding: 5px 0px 3px 8px !important;
    color: #666666 !important;
}

.radius {
    border-radius: 0.25rem !important;
    width: 288px;
}
.downIcon {
    /* margin-left: 35%; */
    position: absolute;
    right: 5px;
    top: 10px;
    font-size: 11px;
    color: #000000d9;
}
.accordion-height {
    height: 35px;
}
.am-option-accordion {
    z-index: 1;
}
.noPadding {
    padding: 5px 0px 0px 7px !important;
}
.am-padding {
    padding-left: 10px !important;
    margin-right: 10px;
}
.hide-accordion {
    display: none;
}
.show-acccordion {
    display: inherit;
}

.am-questionSet {
    max-width: 300px;
    height: 85px;
}

.am-questionSet input {
    margin-top: 10px;
}

.am-question-back {
    padding: 6px 30px;
    margin: 4px;
}

.question-style {
    font-weight: bold;
}

.icon-color {
    color: #0063C3 !important;
    cursor: pointer;
}