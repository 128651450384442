.gims-charts {
    margin-top: 20px;
}

.gims-charts .chart-pad-right {
    padding-right: 8px;
}

.gims-charts .chart-outline {
    border: 1px solid #cccccc;
    background: #ffffff;
    min-height: 300px;
    margin-bottom: 18px;
    border-radius: 5px;
    padding-bottom: 15px;
}
.sub-head-report {
    font-size: 14px;
}
.selectCheckbox {
    position: absolute;
    right: 0;
    margin-right: 18px;
    margin-top: 6px;
    z-index: 1;
}
.pad-adjust .selectCheckbox {
    margin-right: 25px;
}
.mgt-24{
    margin-top: 26.5px !important;
}
.wdt{
    width:100% !important;
}
.hgt{
    height: 35px !important;
}