.content-cneter{
    border: 1px solid lightgray;
    border-radius: 10px;
    margin-top: 3%;
    width: 400px;
    /* height: 553px; */
    margin-left: auto;
    margin-right: auto;
    background: #fff;
}
.amgen{
    border-bottom: 1px solid lightgray;
    background-color: lightgray;
    margin: 0px;
    padding: 15px;
    border-radius: 10px 10px 0px 0px;
}
.reg-form{
       /*padding: 2em;*/
    font-size: 14px;
    font-family: "Open Sans";
    color: #414141;
    letter-spacing: 0;
}
.reg-form .form-control:focus {
    border-color: #0063C3;
    box-shadow: none;
}
/* #role option:hover {
    background: red !important;
} */
.reg-form label {
    font-weight: bold;
}
.reg-form input, .reg-form select, .reg-form textarea {
    font-size: 14px;
    font-weight: normal;
}
.align-lable{
    float: left;
}
.input-width{
width: 30%;
}
.margin{
margin-right: 10px;
}
.email-text-width{
    width: 86%;
}
.textcolor> a, .textcolor> a:hover{
   color: white; 
   text-decoration: none;
}
.fromDispaly{
    display: none;
}
.registration-form {
   padding: 1.3em 2em;
}
.register-header {
    /* background: #f1f1f1; */
    border-radius: 10px 10px 0px 0px;
    /* padding: 10px 0px; */
    margin: 30px 0;
}
.register-header h4 {
    font-size: 1.2em;
    font-weight: bold;
}
.padding-form {
    padding: 0px 16px;
}
.padding-right {
    padding-right: 0 !important;
}
.padding-left {
    padding-left: 0 !important;
}
.error {
  color:red;
  font-size: 0.7em;
  text-align: left;
  /* height: 14px; */
}
.alert-box-error{
    width: 50%;
    margin: auto;
    position: relative;
    margin-top: 7%;
}
.alert-box-user-exist {
    width: 25%;
    margin: auto;
    position: relative;
    margin-top: 7%;
    border: 1px dashed #EA4335 !important;
    border-radius: 10px !important;
    background: #FFEFEF 0% 0% no-repeat padding-box !important;
}
.error-already-exist {
    float: left;
}
.error-msg-exist {
    float: left;
    letter-spacing: 0;
    color: #EA4335;
    font-size: 14px;
}
.registration-form button.cancel, .registration-form button.register {
    border-radius: 5px;
}
.registration-form .padding-form button.cancel,
.registration-form .padding-form button.cancel:active,
.registration-form .padding-form button.cancel:hover,
.registration-form .padding-form button.cancel:focus,
.registration-form .padding-form button.cancel:visited,
.registration-form .padding-form button.cancel:active:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: none;
    border: 1px solid #0063C3;
    color: #0063C3;
}
.registration-form .padding-form button.register,
.registration-form .padding-form button.register:active,
.registration-form .padding-form button.register:hover,
.registration-form .padding-form button.register:focus,
.registration-form .padding-form button.register:visited,
.registration-form .padding-form button.register:active:focus {
    background: #0063C3 0% 0% no-repeat padding-box;
    box-shadow: none;
    border: none;
    color: #FFFFFF;
}
.registration-form .padding-form button.register:disabled {
    background: #CCCCCC;
    color: #666666;
}
.reg-logo-wrapper {
    /* padding-left: 17%; */
    margin-bottom: 30px;
}
.search-list-group {
    position: relative;
    left: 9px;
    bottom: 11px;
    height: 148px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: scroll;
    overflow-x: hidden;
}
.search-list-item {
    padding-top: 5px !important;
    padding-bottom: 2px !important;
    border: 0px !important;

}
.search-list-item:focus, .search-list-item:hover {
    color: #ffffff !important;
    background-color: #0063C3 !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0339A6;
  border-radius: 10px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0063c3; 
}
.logo-size {
    width: 60px;
    height: 14px;
    margin: auto;
    margin-bottom: 10px;
}
.card-header-title {
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E6E6E6;
}
.logo-title {
    font-size: 14px;
    text-align: center;
}

.msg-body {
    padding: 2rem 5rem !important;
    font-size: 14px;
}

.multi-select .dropdown-heading {
   /*  border-color: #0063C3 !important; */
   border-color:#ced4da !important;
    color: #495057 !important;
}

.multi-select .dropdown-content .select-panel .select-list .select-item {
    margin-bottom: 0;
}

.multi-select .dropdown-content .select-panel .select-list .select-item .item-renderer{
    font-weight: normal !important;
}

.multi-select .dropdown-content .select-panel .select-list .select-item .item-renderer input[type="checkbox"] {
    width: 16px;
    height: 16px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #333333 !important; */
}

.select-list .select-item .item-renderer span {
    vertical-align: text-bottom !important;
}

.tea .dropdown-content {
    width: 31em !important;    
}
.checkbox-margin {
    /* margin-top: 10px; */
    padding-top: 20px;
}

.logo {
    letter-spacing: 0px;
    color: #666666;
    text-transform: uppercase;
    opacity: 1;
    font-size: 15px;
    font-family: 'Open Sans'!important;
    line-height: 30px;
}