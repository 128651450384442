.view-details, .edit-questionaries {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.add-scorecard {
    margin-right: 5px !important;
}
/* 
.scorecardSearch {
    margin-top: 30px;
    width: 94px;
} */
.scorecardQueSearch {
    margin-top: 28px;
    width: 94px;
}
.clear, .clear:active{
    background: #FFFFFF !important;
    color: #0063C3 !important;
    border: 1px solid #0063C3 !important;
}


/* ScorecardQuestionaries.css */
/* ScorecardQuestionaries.css */
/* ScorecardQuestionaries.css */
.resizable {
    resize: horizontal;
    overflow: hidden;
    min-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.resizable1 {
    /* resize: horizontal; */
    overflow: hidden;
    min-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
}