.header {
    width: 100%;
    float: left;
    box-shadow: 1px 1px 1px 1px #ccc;
    position: fixed;
    z-index: 999;
    background: #fff;
}
.header-title {
    height: 60px;
    line-height: 60px;
    text-align: left;
    font-weight: bold;
    width: 60%;
    float: left;
    padding-left: 1em;
}
.header-title .logo {
    letter-spacing: 0;
    color: #666666;
    text-transform: uppercase;
    opacity: 1;
    font-size: 16px;
    display: inline;
    font-family: 'Open Sans';
}
.header-title .logo span {
    color: #ccc;
}
.header-title .pipe {
    padding: 0px 6px;
    font-size: 1.5em;
    color: #ccc;
}
.header-title .header-title-name {
    font-size:1.2em;
}
.header-right {
    float: right;
    /* width: 13%; */
    font-size: 12px;
    /* line-height: 30px; */
}
.imageStyle{
    height: 32px;
    width: 32px;
    /* border: 1px solid gray; */
    margin-top: 15px;
    float: left;
    /* border-radius: 50%; */

    font-size: 25px;
    padding-top: 2px;
    padding-left: 4px;
   
}

.header-label {
    float: left;
    line-height: 15px;
    margin-top: 15px;
    margin-left: 5px;
}
.firstName {
    font-size: 14px;
    line-height: 16px;
    color: #333333;
}
.roleStyles{
    color: #666666;
}
.power {
    height: 20px;
    width: 18px;
    margin-top: 17px;
    float: right;
    font-size: 17px;
    padding-top: 2px;
    margin-right: 20px;
}
.user-guide {
    font-size: 14px;
    line-height: 16px;
}