.multiSelheight .dropdown-heading {
    height: 35px !important;
    margin-top: 0px;
}
.multiSelheight .dropdown-heading-value {
    line-height: 32px !important;
}
.calender-set {
    width:100%;
}
.calender-set .p-datepicker-trigger.p-button {
    border-color: #0063C3 !important;
}
.calender-set .p-inputtext {
    border-color: #0063C3 !important;
}

.switch-field {
    padding-top: 30px;
    overflow: hidden;
}

.switch-field .form-check-inline {
    margin-right: 0px !important;
}
  
.switch-field .form-check-inline input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}
  
.switch-field .form-check-inline label {
    display: inline-block;
    width: 100px;
    background-color: #ffff;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 4px 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
}
  
  .switch-field input:checked + label {
    background-color: #0063C3;
    color: #fff;
    box-shadow: none;
  }

  .switch-field label:hover {
    cursor: pointer;
  }

  .switch-field .form-check-inline label:last-of-type {
    border-radius: 0 4px 4px 0;
  }
  
  .switch-field .switch_left label:first-of-type {
    border-radius: 4px 0 0 4px !important;
  }