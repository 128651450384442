table {
    border: 1px solid gray;
    border-collapse: collapse;
    width:100%
  }
td, th {
    text-align: left;
    padding: 8px;
    font-size: 14px;
  }
 .table-border{
  border: 1px solid #CCCCCC;
 }
  th{
    background: #F2F2F2 0% 0% no-repeat padding-box;
    /* border: 1px solid #CCCCCC; */
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    color: #333333;
    font-size: 14px;
  }
.addUser{
    float:right;
    /* margin-right: 100px; */
    /* margin-top: 10px; */
    padding: 5px 16px !important;
}
.dashboardpadding{
  padding-right: 0px !important;
}
.content-padding{
  padding-left: 0px !important;
}

.pageNumbers{
  flex-direction: row !important;
}
.pageNumbers>.active.list-group-item>a{
color: white !important;
}
.padding-top{
  padding-top: 15px;
}
.verticalAlign{
  vertical-align: -webkit-baseline-middle;
}
.pageNumbers.list-group .list-group-item{
  padding: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  cursor: pointer;
  border-color: #0063C3 !important;
  margin-right: 3px;
    border-radius: 5px;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0063C3 !important;
  /* border-color: #0063C3 !important; */
  border-color: #023373 !important;
}
.list-item-padding {
  padding: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  cursor: pointer;
}
.set-margin-right{
  margin-right: 15px;
}
.action-center{
  text-align: center;
}
.iconStyle{
  /* border: 1px solid #686868; */
    font-size: 12px;
    padding: 1px 12px 0px 12px;
    border-radius: 50%;
    background: transparent url('../../../public/Images/ico-info.png') 0% 0% no-repeat padding-box;
}
.iconStyle:hover{
  color: white;
  background-color: black;
  cursor:pointer;
  background: transparent url('../../../public/Images/ico-info-filled.png') 0% 0% no-repeat padding-box;
}

.pop{
  margin: 115px;
}

.deletionModal{
  max-width: 444px !important;
  text-align: center;
}
.image-padding{
  /* padding-right: 3px; */
  margin-right: 5px;
  margin-top: 3px;
  margin-left: 0px;
}
.approve-style{
  color: #0F9D58;
  cursor: pointer;
  display: inline-flex;
  /* margin-left:15px; */
}
.reject-style{
  color: #EA4335;
  cursor: pointer;
  display: inline-flex;
  margin-left: 10px;
}
.delete-style{
  color: #EA4335;
  cursor: pointer;
  display: inline-flex;
}
.notify-style{
  color: #0063C3;
  cursor: pointer;
}
.border-right{
  border-right: 1px solid #CCCC;
}
.confirmation-header{
  border-bottom: none !important;
    padding: 1rem 9rem !important;
    color: #023373;

}
.alert-header{
  border-bottom: none !important;
    padding: 1rem 13rem !important;
    color: #023373;

}
.confirmation-body{
  font-size: 14px;
  padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.confirmation-footer{
  border-top: none !important;
  padding: 1rem 8rem !important;
}
.alert-confirmation-footer{
  border-top: none !important;
    /* padding: 1rem 14rem !important; */
    margin: auto;
}
.confirmation-footer .btn-primary,
.alert-confirmation-footer .btn-primary{
  padding: .375rem 2rem !important;
  font-size: 14px;
}
.add-user-header{
  padding-left: 13rem !important;
    background: #0339A6;
    color: white;
    border: 1px solid #023373;
    font-weight: bold;
}
.add-user-header .close{
  font-weight: normal !important;
  color: #FFFFFF !important;
  opacity: 1;
}
.confirmation-header .close{
  padding: 1rem 1rem;
    /* margin: -1rem -1rem -1rem auto; */
    right: 20px ;
    position: absolute;
    top: 8px;
}
.user-deletion-cancel{
  margin-top: 30px;
}
/* .popover .arrow{
  width: 1rem;
    height: .5rem;
    margin: 0 .3rem;
    left: 28px !important;
} */
/* .popover-header{
  float: right !important;
} */

.edit-align-center{
  text-align: center;
}
.edit-align-right {
  text-align:right;
}
.edit-user-icon{
  /* margin-top: 0px;
  margin-left: 5px; */
  cursor: pointer;
  margin-right: 5px;
  margin-top: 3px;
  margin-left: 0px;
}
.edit-only-user {
  /* float: right; */
  /* margin-right: -9px; */
  margin-top: 3px;
}
.editIcons{
  float: left;
}
.edit-align-right{
  margin-left: 22px;
}
.edit-style{
  color:#005cbf !important;
  display: inline-flex;
  cursor: pointer;

}