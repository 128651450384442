
.details-layout {
	border: 1px solid #ccc;
    padding: 9px;
	margin: auto;
    border-radius: 4px;
    background: #ffffff;
    font-size: 14px;
    color: #333333;
}
.details-layout label {
	font-weight: bold;
    margin-bottom: 0px;
    min-width: 33%;
    word-break: break-word;
    width:50%;
    text-align: right;
    float: left;
}
.details-layout span {
    color: #333333;
    float: right;
    /* width: 60%; */
}
.details-layout .row{
	padding-bottom: 20px;
}
.content-layout{
	float: left;
	width: 100%;
}
.scroll{
    max-height: 75px;
    overflow-y: auto;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
}
.scroll::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  .scroll::-webkit-scrollbar-thumb {
    background: gray !important; 
  }
.heading-interaction {
	font-size: 20px;
}
.details-row {
    padding: 1rem 2rem;
}

.details-row .status {
	color: #0F9D58;
}
.layout-2 {
	padding: 0px 10px;
}
.layout-2 .fileLayout {
	border: 2px dashed #cccccc;
    padding: -3px;
    height: 59px;
    position: relative;
    margin: auto;
    width: 100%;
    margin-left: 0px !important;
    border-radius: 4px;
    background:#F2F2F2;
    margin-top: 26px;
}
.interaction-details-screen .layout-2 .fileLayout {
    float: left;
    height: auto;
    min-height: 70px;
}
.fileLayout .files {
	padding: 16px;
	display: inline-block;
    width: 17%;
}
.fileLayout .file-boxes {
	padding: 7px 10px;
    /* display: inline-block; */    
}

.interaction-details-screen .fileLayout .file-boxes {
    display: block;
    float: left;
}



.fileLayout .file-box {
	border: 1px solid #0063C3;
	border-radius: 15px;
	color:#0063C3;
	/*padding: 0.3rem 4rem 0.3rem 0.4rem;*/
    position: relative;
    background: #fff;
    padding: 2px 14px 4px 2px;
    cursor: pointer;
    /* width: max-content; */
    width: auto;
    white-space: nowrap;
    display: flex;
}
.fileLayout .file-name {
	/* display: inline; */
	/*padding-left: 8px;*/
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
}
.fileLayout .file-download img {
	width: 16px;
	height: 16px;
/*	position: absolute;
    top: 6px;
    right: 8px;
    cursor: pointer;*/
}
.fileLayout .file-download {
    width: unset;
}

.fileLayout .file-download i {
    width: 12px;
    height: 13px;
    color: #EA4335;
    position: relative;
    left: 5px;
    top: 1px;
}
.btn-goback{
	background: #fff;
    border: 1px solid #0063C3;
    border-radius: 5px;
    color: #0063C3;
    padding: 8px 30px;
    margin-right: 10px;
}
.edit-case-owner {	
    margin-top: -5px;
    margin-left: 5px;
    cursor: pointer;
}
.back_btn {
    position: relative;
    right: 1em;
}

/* .col-row-1 {
    flex: 0 0 25.333333% !important;
    max-width: 25.333333% !important;
}
.col-row-2 {
    flex: 0 0 31.333333% !important;
    max-width: 31.333333% !important;
}
.col-row-3 {
    flex: 0 0 43.333333% !important;
    max-width: 46.333333% !important;
}
.col-row-1 label {
    width: 46%;
}
.col-row-3 label {
    width: 58%;
}
.col-row-1 span {
    width: 50%;
}
.col-row-3 span {
    width: 40%;
} */
/* .col-row-3 label {
    width: 55%;
} */

.fileLayout .drag-text {
    position: absolute;
    top: 65%;
    right: 0%;
    margin-right: 0;
    -ms-transform: translate(-2px, -33px);
    transform: translate(-2px, -33px);
    margin-left: -3%;
}
.fileLayout .file-drop-icon {
    width: 32px;
    height: 27px;
    display: block;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    left: -45px;
}
.model-content-assign .modal-content{
    width:80%;
}
.model-content-assign .add-user-header {
    padding-left: 10rem !important;
}
.team-form-group {
    width: 100%;
    padding: 0em 2em;
}
.team-form-group label {
    color: #414141;
    font-weight: bold;
    font-size: 14px;
}
.team-form-group .form-control {
    color: #666666;
    font-size: 14px;
}
.team-form-button {
    width: 100%;
}
.team-form-button .text-btn-color, .team-form-button .text-btn-color:hover, .team-form-button .text-btn-color:active {
    color:#ffffff;
    background: #0063C3;
    float: right;
    margin-right: 8%;
    width: 133px;
    height: 40px;
}

.upload
{
    display: inline-block;
}

.file{
  
    height: 54px;
    width: 71%;
    display: inline-flex;
    overflow-x: scroll;  
}
.interaction-details-screen .file {
    height: auto;
    display: inline;
    overflow: auto;
    float: left;
    width: 75%;
}

.select{
    width:29%;  
}
.interaction-details-screen .select {
    height: 70px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.max-file
{
    height: 54px;
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;  
}

.max-file::-webkit-scrollbar {
    height: 10px;    
  }

  .file::-webkit-scrollbar {
    height: 10px;    
  }
  .no-team-available-msg {
    width: 300px;
    height: 75px;
    background: #E5F2FF 0% 0% no-repeat padding-box;
    border: 1px dashed #0063C3;
    border-radius: 10px;
    opacity: 1;
    text-align: left;
    padding: 14px;
  }
  .no-team-available-msg span {
      text-decoration: underline;
      color: #0063C3;
      cursor: pointer;
  }
  .expand-agentNotes{
      float: right !important;
      height: 30px;
      width: 30px;
      padding: 0px !important;
      margin-bottom: 4px;
      /* margin-bottom: 6px;
      margin-top: -9px; */
  }
  .expand-action{
    width: 20px;
    height: 20px;
    padding-bottom: 3px;
    /* margin-top: -5px;
    margin-left: -2px; */
  }
  .agentNotes-iframe{
      width:100%;
      max-height:140px;
      border: 1px solid #ccc;
      border-radius: 2px;
  }
  
  .agentNotes-iframe::-webkit-scrollbar-thumb {
    background: #0339A6 !important;
    border-radius: 10px; 
  }
  .agentNotes-iframe::-webkit-scrollbar {
    width: 5px;
  }
  .scrollFileNames{
    max-height: 295px;
    overflow-y: auto;
    width: 100%;
  }
  .model-content-translate .translate-header {
    padding-left: 0.5rem !important;
  }
  .selectType span {
      padding-left: 10px;
  }
  .selectType .form-label {
    padding-right:10px;
}
.model-content-translate .team-form-group {
    padding: 0 !important;
}
.model-content-translate .modal-content {
    width: 70%;
    margin: auto;
}
.translationLayout {
    margin-top:0px !important;
}
.translationLayout .row{
    margin:0;
    padding-bottom:10px;
    margin-top:10px;
}
.translationLayout label {
    text-align:left;
    width: 25%;
    min-width: 20%;
}
.translationLayout span {
    float:left;
}
.translationLayout .orderedAttach {
    display: table-cell;
    position: relative;
    right: 20%;
}

.bio-connect-link {
    text-decoration: underline !important;
    color:#0063C3 !important;
    cursor: pointer;
}