.width1{
    width: 35%;
    /* text-align: right; */
}
/*.width2{
    width:9%;
    /* text-align: right; */
/* }
.width3{
    width:6%;
}  */
.width4{
    width: 10%;
    /* text-align: right; */
}
.popInteracation{
    left: 78px !important;
    margin: 115px;
}
.popInteracation .arrow{
    left: 138px !important;
}
/* .countryTotalRow td{
    text-align: right;
} */