.landing-container {     
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 400px;
    height: 378px;
    background: #fff;
}
.landing-container .landing-header {
	padding: 1rem 4rem;
    padding-bottom: 0px;
    margin-top: 30px;
}
.landing-container .landing-wrapper {
	 padding: 3rem 3rem;
}
.landing-container .landing-wrapper button.sign-in,
.landing-container .landing-wrapper button.sign-in:active,
.landing-container .landing-wrapper button.sign-in:hover,
.landing-container .landing-wrapper button.sign-in:focus,
.landing-container .landing-wrapper button.sign-in:visited,
.landing-container .landing-wrapper button.sign-in:active:focus,
.landing-container .landing-wrapper button.register,
.landing-container .landing-wrapper button.register:active,
.landing-container .landing-wrapper button.register:hover,
.landing-container .landing-wrapper button.register:focus,
.landing-container .landing-wrapper button.register:visited,
.landing-container .landing-wrapper button.register:active:focus {
    background-color: #0063C3;
    box-shadow: none;
    text-align: center;
    font: Regular 16px/22px "Open Sans";
    letter-spacing: 0;
    color: #FFFFFF;
    border-radius: 5px;
}
.landing-container .landing-wrapper .row:first-of-type {
    margin-bottom: 30px;
}
.landing-container .landing-wrapper .row:last-of-type {
    margin-top: 30px;
}
.ls-logo-wrapper {
    margin-bottom: 30px;
}

