.notify{
    background-color: grey;
}
.or-circle h5 {
	border: 1px solid #99C3EC;
    width: 42px;
    height: 42px;
    padding: 12px;
    border-radius: 50%;
    font-size: 14px;
    color:#666666;
    background: #fff;
    margin-right: 0px !important;
    left: 49% !important;    
}
.or-circle {
    margin-top: 55px;
    z-index: 1;
}
.file-row {
	border: 2px dashed #99C3EC;
    padding-top: 7px;
    position: relative;
    margin: auto;
    width: 100%;
    margin-left: 0px !important;
    border-radius: 4px;
    background: #E6F3FF;
}
.drag-text {
	margin: 0;
    position: absolute;
    top: 33%;
    left: 50%;
    right: 35%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #0063C3;
}
.sample-count-width {
    width: 15%
}
.plus-min{
     display: inline-block;
     /* margin-right: 5px; */
     width:12%;
     vertical-align: bottom;
     /* margin-bottom: 5%; */
}
.plus-min .plus-min-box {
    width: 20px;
    height: 20px;
    border: 1px solid #0063C3; 
    vertical-align: middle;
    text-align: center;
    padding-bottom: 8px !important;
    cursor: pointer;
    background: #fff;
}
.plus-min .minus {
    border-radius: 4px 0px 0px 4px;
    padding: 6px 15px;
    /* border-right: 0; */
}
.plus-min .percent {
    border-radius: 0px;
    padding: 6px 14px 6px 8px; 
    /* padding: 6px 10px 7px 4px; */
    color: #414141;
}
.plus-min .plus {
    border-radius: 0px 4px 4px 0px;
    padding: 6px 14px;
    /* border-left: 0; */
}
span.plus-min-box.plus:hover, span.plus-min-box.minus:hover {
    background: #0063C3;
    color: #fff;
}
.downloadLink {
    text-decoration: none !important;
    color:#0063C3 !important;
    cursor: pointer;
   
}
.dataset-tableheader{
    padding-left:97px;
}
.sampling{
    text-decoration: none !important;
    color:#0063C3 !important;
    cursor: pointer;
    padding-right:21px;
}
.delete-error{
    padding-left:14px !important;
}
.delete-error-no-padding{
    padding-left:0px !important;
}
a.delete-error-no-padding {
    color: #EA4335 !important;
    cursor: pointer;
}
.delete-align {
    vertical-align: middle;
}
.del-image-padding{
    margin-top: 3px;
    margin-left: 0px;
}
.dataset-action {
    width: 13px;
    height: 13px;
   /*  position: relative;
    top: -2px;
    right: 6px; */
    margin-right: 5px;
    margin-bottom: 3px;
}
.dataset-icon {

    margin-right: 10px;
}

.btn-extract {
    background: #0063C3;
    color: #fff;
    border: 1px solid #0063C3;
    padding: 4px 14px 9px 14px;
    border-radius: 5px;
    vertical-align: bottom;
    margin-bottom: 4%;
}

.clearDataset{
    background: #FFFFFF !important;
    color: #0063C3 !important;
    border: 1px solid #0063C3;
    border-radius: 5px;
    padding-left: 32px !important;
    padding-right: 32px !important;
  
}
.dataset-title{
    font-size: 20px;
    text-transform: capitalize;
    color: #333;
}
.extract-btn-icon {
    width: 18px;
    height: 15px;
    margin-right: 10px
}
.extract-text {
    position: relative;
    top: 2px;
}
.datasetname-input {
    clear: both;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    opacity: 1;
    width: 300px;
    height: 40px;
}
.dataset-name-entry-content {
    width: 300px;
    margin-left: 33px;
}
.dataset-list-screen-dialog .modal-dialog {
    width: 400px !important;
}

.dataset-list-screen-dialog .modal-header {
    background-color: #0339A6;
    color:#fff;
}
.dataset-list-screen-dialog .modal-header .modal-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}
.dataset-list-screen-dialog .modal-header .close {
    color: #fff;
    opacity: 1;
}
.dataset-list-screen-dialog .modal-footer {
    border: 0;
}
.dataset-list-screen-dialog .modal-footer .btn {
    background-color: #0063C3;
    font-size: 14px !important;
}
/* .contentChange input::after{
    content:"%"
} */
.percentStyle{
    border: none;
    width: 10%;
    text-align: right;
}
.percentStyle:focus{
    outline: none;
}

.datasetStartdate{
    display: inline-grid;
    vertical-align: bottom;
    margin-bottom: 4%;
}

.toggle-icon {
    display: inline-flex;
    margin-right: 10px;
}

.datasetEnddate {
    display: inline-grid;
    vertical-align: bottom;
    margin-bottom: 4%;
    margin-left:15px;
    margin-right:15px;
}
/* .dataset-extract-dialog {

} */

.dataset-extract-dialog .modal-dialog {
    width: 400px !important;
}

.dataset-extract-dialog .modal-header {
    background-color: #ffffff;
    color:#023373;
    border-bottom: 0;
}
.dataset-extract-dialog .modal-header .modal-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}
.dataset-extract-dialog .modal-header .close {
    color: #fff;
    opacity: 1;
}
.dataset-extract-dialog .modal-footer {
    border: 0;
}
.dataset-extract-dialog .modal-footer .btn {
    background-color: #0063C3;
    font-size: 14px !important;
}
.progressMsg {
    font-size: 13px;
    color: #666666;
    margin-top: 6px;
    margin-bottom: -5px;
    font-style: italic;
}
.emptyAnchorTag{
    padding-left:107px;
}
.dataset-team {
    margin-right: 10px;
}
.dataset-team .multi-select {
    width: 150px;
}
.drag-drop {
    padding: 10px 0px 0px 10px !important;
    text-align: center;
}
.text-fields {
    padding: 0px !important;
}
.name-error {
    font-size: 12px;
    text-align: left;
}
.extract {
    margin-left: 2%;
    padding-top: 4%;
}
.line {
    width: 87%;
    height: 27px;
    margin-top: 53px;
    margin-left: 50px;
    border-bottom: 1px solid #5799d9;
    position: absolute;
}

.d-width {
    width: 15%;
}

.t-width {
    width: 20%;
}

.sample-count {
    position: absolute;
    top: 50%;
    display: flex;
    margin-left: 55%;
}

.sample-count label{
    padding: 5px;
}

.sample-count input {
    width: 50%;
    flex-grow: 1;
    margin-left: 20px;
}

.btn-disabled {
    cursor: no-drop;
    background-color: #80808087;
    border: 1px solid #80808087;
}

.label-names {
    font-size: 13px;
    font-weight: bold;
}

.tooltip-container{
    position: relative;
    display: inline-block;
    margin-left: 8px;
}

.hint-icon{
    /* margin-left: 4px; */
    /* font-size: 18px; */
    width: 20px;
    height: 20px;
    cursor: pointer;
    /* color: #007ad9; */
}

.tooltip-text{
    /* visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f7f0f0;
    color: #141313;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 10; */
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #4b4a4a;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 10;
    border: 1px solid #ccc;
    /* padding: 9px; */
	margin: auto;
    border-radius: 4px;
}

.tooltip-container:hover .tooltip-text{
    visibility: visible;
    opacity: 1;
}


.hint-icon:hover::after{
    color: #005bb5;
}